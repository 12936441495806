import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { dateToStr, getDurationString, MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from "../../utils/dates";
import { ConferenceType as ConferenceTypeDto, RequestParams, TimePolicy as TimePolicyDto } from "../client";
import { EasyTypedErrorPromise } from "../domainTypeHelpers";
import { CalendarEvent, calendarEventToDto, dtoToCalendarEvent } from "../Events";
import { nullable, TransformDomain } from "../types";
import { ThinPerson, TimePolicy, timePolicyToDto } from "../Users";
import {
  CreateSchedulingLinkRequest as CreateSchedulingLinkRequestDto,
  IconType as SchedulingLinkIconTypeDto,
  PartialSchedulingLinkMeeting as PartialSchedulingLinkMeetingDto,
  ReclaimApi,
  SchedulingLink as SchedulingLinkDto,
  SchedulingLinkMeetingAvailability as SchedulingLinkMeetingAvailabilityDto,
  SchedulingLinkMeetingAvailabilitySlot as SchedulingLinkMeetingAvailabilitySlotDto,
  SchedulingLinkMeetingEvent as SchedulingLinkMeetingEventDto,
  SchedulingLinkMeetingRequest as SchedulingLinkMeetingRequestDto,
  SchedulingPriority as SchedulingLinkPriorityDto,
  SchedulingPriority as SchedulingPriorityDto,
  TimePolicyType as TimePolicyTypeDto,
  UserSlug as UserSlugDto,
} from "./scheduling-links-client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export type SchedulingLinkIconType = `${SchedulingLinkIconTypeDto}`;
export type SchedulingLinkPriority = `${SchedulingLinkPriorityDto}`;
export type TimePolicyType = `${TimePolicyTypeDto}`;
export type ConferenceTypeStr = `${ConferenceTypeDto}`;

/**
 * SchedulingLink
 */

export type SchedulingLink = Readonly<
  Override<
    SchedulingLinkDto,
    {
      id: string;
      startDate: Date | undefined;
      endDate: Date | undefined;
      organizer: ThinPerson;
      timePolicyType: TimePolicyType;
      oneOffPolicy?: TimePolicy;
      iconType: SchedulingLinkIconType;
      conferenceType?: ConferenceTypeStr;
      slug: string;
      description?: string;
      daysIntoFuture?: number;
      location?: string;
      organizerRefCode?: string;
    }
  >
>;

export type CreateSchedulingLinkRequest = Override<
  CreateSchedulingLinkRequestDto,
  {
    startDate?: Date;
    endDate?: Date;
    timePolicyType: TimePolicyType;
    oneOffPolicy?: TimePolicy;
    iconType: SchedulingLinkIconType;
    priority: SchedulingLinkPriority;
    slug: string;
  }
>;

/**
 * There no longer is a real PatchSchedulingLinkRequestDto, the typing
 * has been replaced by Object, but there is a real object type that
 * the server expects
 */
export type PatchSchedulingLinkRequestDto = Partial<
  Override<
    Omit<SchedulingLinkDto, "organizer" | "id">,
    {
      title: string | null;
      description: string | null;
      enabled: boolean;
      timePolicyType: TimePolicyTypeDto;
      oneOffPolicy: TimePolicyDto | null;
      daysIntoFuture: number | null;
      startDate: string | null;
      endDate: string | null;
      priority: SchedulingPriorityDto;
      iconType: SchedulingLinkIconTypeDto;
    }
  >
>;

export type PatchSchedulingLinkRequest = Partial<
  Override<
    PatchSchedulingLinkRequestDto,
    {
      timePolicyType: TimePolicyType;
      oneOffPolicy: TimePolicy | null;
      priority: SchedulingLinkPriority;
      iconType: SchedulingLinkIconType;
      conferenceType: ConferenceTypeStr | null;
      startDate: Date | null;
      endDate: Date | null;
    }
  >
>;

export type UserSlug = Readonly<
  Override<
    UserSlugDto,
    {
      slug: string;
    }
  >
>;

export const dtoToSchedulingLink = (dto: SchedulingLinkDto): SchedulingLink => ({
  ...dto,
  startDate: dto.startDate ? new Date(dto.startDate) : undefined,
  endDate: dto.endDate ? new Date(dto.endDate) : undefined,
  timePolicyType: dto.timePolicyType as TimePolicyType,
  organizer: dto.organizer as ThinPerson,

  // null -> undefined conversions
  description: dto.description || undefined,
  daysIntoFuture: dto.daysIntoFuture || undefined,
  location: dto.location || undefined,
  organizerRefCode: dto.organizerRefCode || undefined,
});

export const createSchedulingLinkRequestToDto = (
  link: CreateSchedulingLinkRequest
): CreateSchedulingLinkRequestDto => ({
  ...link,
  timePolicyType: link.timePolicyType as TimePolicyTypeDto,
  startDate: nullable(link.startDate, dateToStr),
  endDate: nullable(link.endDate, dateToStr),
  priority: link.priority as SchedulingLinkPriorityDto,
  iconType: link.iconType as SchedulingLinkIconTypeDto,
});

export const patchSchedulingLinkRequestToDto = (link: PatchSchedulingLinkRequest): PatchSchedulingLinkRequestDto => ({
  ...link,
  timePolicyType: link.timePolicyType as TimePolicyTypeDto,
  startDate: nullable(link.startDate, dateToStr),
  endDate: nullable(link.endDate, dateToStr),
  priority: link.priority as SchedulingLinkPriorityDto,
  iconType: link.iconType as SchedulingLinkIconTypeDto,
  oneOffPolicy: link.oneOffPolicy && timePolicyToDto(link.oneOffPolicy),
});

export const userSlugtoDto = (dto: UserSlugDto): UserSlug => ({ ...dto });

/**
 * SchedulingLinkMeetingAvailabilitySlot
 */

export type SchedulingLinkMeetingAvailabilitySlot = Readonly<
  Override<
    Omit<SchedulingLinkMeetingAvailabilitySlotDto, "suggested">,
    {
      startTime: Date;
      endTime: Date;
    }
  >
>;

export const dtoToSchedulingLinkMeetingAvailabilitySlot = (
  dto: SchedulingLinkMeetingAvailabilitySlotDto
): SchedulingLinkMeetingAvailabilitySlot => ({
  ...dto,
  startTime: new Date(dto.startTime),
  endTime: new Date(dto.endTime),
});

export const schedulingLinkMeetingAvailabilitySlotToDto = (
  data: SchedulingLinkMeetingAvailabilitySlot
): SchedulingLinkMeetingAvailabilitySlotDto => ({
  ...data,
  startTime: data.startTime.toISOString(),
  endTime: data.endTime.toISOString(),
});

/**
 * SchedulingLinkMeetingAvailability
 */

export type SchedulingLinkMeetingAvailabilityTimes = Record<number, SchedulingLinkMeetingAvailabilitySlot[]>;

export type SchedulingLinkMeetingAvailability = Readonly<
  Override<
    SchedulingLinkMeetingAvailabilityDto,
    {
      inviteeEvents?: CalendarEvent[];
      availableTimes: SchedulingLinkMeetingAvailabilityTimes;
    }
  >
>;

export const dtoToSchedulingLinkMeetingAvailability = (
  dto: SchedulingLinkMeetingAvailabilityDto
): SchedulingLinkMeetingAvailability => ({
  ...dto,
  inviteeEvents: dto.inviteeEvents?.map(dtoToCalendarEvent),
  availableTimes: Object.entries(dto.availableTimes).reduce((map, [key, slots]) => {
    map[key] = slots.map(dtoToSchedulingLinkMeetingAvailabilitySlot);
    return map;
  }, {} as Record<string, SchedulingLinkMeetingAvailabilitySlot[]>),
});

export const schedulingLinkMeetingAvailabilityToDto = (
  data: SchedulingLinkMeetingAvailability
): SchedulingLinkMeetingAvailabilityDto => ({
  ...data,
  inviteeEvents: data.inviteeEvents?.map(calendarEventToDto),
  availableTimes: Object.entries(data.availableTimes).reduce((map, [key, slots]) => {
    map[key] = slots.map(schedulingLinkMeetingAvailabilitySlotToDto);
    return map;
  }, {} as Record<string, SchedulingLinkMeetingAvailabilitySlotDto[]>),
});

/**
 * SchedulingLinkMeetingRequest
 */

export type SchedulingLinkMeetingRequest = Readonly<
  Override<
    SchedulingLinkMeetingRequestDto,
    {
      start: Date;
      end: Date;
    }
  >
>;

export const dtoToSchedulingLinkMeetingRequest = (
  dto: SchedulingLinkMeetingRequestDto
): SchedulingLinkMeetingRequest => ({
  ...dto,
  start: new Date(dto.start),
  end: new Date(dto.end),
});

export const schedulingLinkMeetingRequestToDto = (
  data: SchedulingLinkMeetingRequest
): SchedulingLinkMeetingRequestDto => ({
  ...data,
  start: data.start.toISOString(),
  end: data.end.toISOString(),
});

/**
 * SchedulingLinkMeetingEvent
 */

export type SchedulingLinkMeetingEvent = Readonly<
  Override<
    SchedulingLinkMeetingEventDto,
    {
      event: CalendarEvent;
      attendee: ThinPerson;
    }
  >
>;

export const dtoToSchedulingLinkMeetingEvent = (dto: SchedulingLinkMeetingEventDto): SchedulingLinkMeetingEvent => {
  if (typeof dto.meetingId !== "string") throw new Error("SchedulingLinkMeetingEvent must have a meetingId");
  return {
    ...dto,
    event: dtoToCalendarEvent(dto.event),
  };
};

/**
 * PartialSchedulingLinkMeeting
 */

export type PartialSchedulingLinkMeeting = Readonly<
  Override<
    PartialSchedulingLinkMeetingDto,
    {
      start?: Date | null;
      end?: Date | null;
    }
  >
>;

export const partialSchedulingLinkMeetingToDto = (
  data: PartialSchedulingLinkMeeting
): PartialSchedulingLinkMeetingDto => ({
  ...data,
  start: data.start?.toISOString(),
  end: data.end?.toISOString(),
});

export class SchedulingLinksDomain extends TransformDomain<SchedulingLink, SchedulingLinkDto> {
  /**
   * This domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "SchedulingLink";
  cacheKey = "scheduling_links";
  pk = "id";

  public deserialize = dtoToSchedulingLink;

  list = this.deserializeResponse(() => this.domainApi.schedulingLink.getAllLinks());

  get = this.deserializeResponse((id: string) => this.domainApi.schedulingLink.getLink(id));

  getAllLinksForUserSlug = this.deserializeResponse((userSlug: string) =>
    this.domainApi.schedulingLink.getAllLinksForUserSlug(userSlug)
  );

  create = this.deserializeResponse((payload: CreateSchedulingLinkRequest) =>
    this.domainApi.schedulingLink.createLink(createSchedulingLinkRequestToDto(payload))
  );

  patch = this.deserializeResponse((id: string, payload: PatchSchedulingLinkRequest) =>
    this.domainApi.schedulingLink.updateLink(id, patchSchedulingLinkRequestToDto(payload))
  );

  getMeetingSlots = async (schedulingLinkId: string, date: Date): Promise<SchedulingLinkMeetingAvailability> => {
    return dtoToSchedulingLinkMeetingAvailability(
      await this.domainApi.schedulingLink.getMeetingSlots(schedulingLinkId, {
        requestDate: `${date.toISOString()}`,
      })
    );
  };

  delete = (id: string) => this.domainApi.schedulingLink.deleteLink(id);

  getUnavailableDates = (linkId: string, dateList: string[], params?: RequestParams) =>
    this.domainApi.schedulingLink.getAvailabilityForDates(linkId, dateList, params);

  requestMeeting = async (
    schedulingLInkId: string,
    request: SchedulingLinkMeetingRequest
  ): Promise<SchedulingLinkMeetingEvent> =>
    dtoToSchedulingLinkMeetingEvent(
      (await this.domainApi.schedulingLink.createMeeting(
        schedulingLInkId,
        schedulingLinkMeetingRequestToDto(request)
      )) as SchedulingLinkMeetingEventDto
    );

  getMeeting = async (meetingId: string) =>
    dtoToSchedulingLinkMeetingEvent(
      (await this.domainApi.schedulingLink.findMeeting(meetingId)) as SchedulingLinkMeetingEventDto
    );

  cancelMeeting = async (meetingId: string) => this.domainApi.schedulingLink.deleteMeeting(meetingId);

  updateMeeting = async (meetingId: string, patch: PartialSchedulingLinkMeeting) =>
    this.domainApi.schedulingLink.updateMeeting(meetingId, partialSchedulingLinkMeetingToDto(patch));

  getMyUserSlug = () => this.domainApi.schedulingLink.getMyUserSlug();

  userSlugExists = (userInputSlug: string) =>
    this.domainApi.schedulingLink.userSlugExists({ slug: userInputSlug }) as unknown as EasyTypedErrorPromise<boolean>;

  getLinkForSlugs = (userSlug: string, linkSlug) =>
    this.domainApi.schedulingLink.getLinkForUserAndLinkSlug(userSlug, linkSlug);

  updateMyUserSlug = (payload: UserSlug) => this.domainApi.schedulingLink.updateMyUserSlug(userSlugtoDto(payload));

  schedulingLinkSlugExists = (slug: string) =>
    this.domainApi.schedulingLink.schedulingLinkSlugExists({ slug }) as unknown as EasyTypedErrorPromise<boolean>;

  updateUserSlug = (slug: string) =>
    this.domainApi.schedulingLink.updateMyUserSlug({ slug }) as unknown as EasyTypedErrorPromise<boolean>;

  listMeetingsForSchedulingLink = async (schedulingLinkId: string) =>
    (await this.domainApi.schedulingLink.listMeetingsForSchedulingLink(schedulingLinkId)).map(
      dtoToSchedulingLinkMeetingEvent
    );
}

export const getSchedulingLinkDurationStr = (link: SchedulingLink): string => {
  if (!link.durations.length) return "No duration set";

  link.durations.sort((a, b) => a - b);

  if (link.durations.length === 1) {
    return getDurationString(link.durations[0] * MILLISECONDS_PER_MINUTE);
  } else if (link.durations.length === 2) {
    return `${getDurationString(link.durations[0] * MILLISECONDS_PER_MINUTE)}, ${getDurationString(
      link.durations[1] * MILLISECONDS_PER_MINUTE
    )}`;
  } else {
    return `${getDurationString(link.durations[0] * MILLISECONDS_PER_MINUTE)}, ${getDurationString(
      link.durations[1] * MILLISECONDS_PER_MINUTE
    )}, ${getDurationString(link.durations[2] * MILLISECONDS_PER_MINUTE)}`;
  }
};

export const getSchedulingLinkDurationStrLessUnits = (link: SchedulingLink): string => {
  if (!link.durations.length) return "No duration set";

  link.durations.sort((a, b) => a - b);

  // if all durations are <1hr - return only last one with units (mins)
  // or if all durations are divisable by one hour - only return last one with units (hrs)
  if (
    link.durations.every((duration) => duration * MILLISECONDS_PER_MINUTE < MILLISECONDS_PER_HOUR) ||
    link.durations.every((duration) => (duration * MILLISECONDS_PER_MINUTE) % MILLISECONDS_PER_HOUR === 0)
  ) {
    // map through array
    // only return the last duration string WITHOUT the noUnit:true option
    return link.durations
      .map((duration, index, { length }) =>
        getDurationString(duration * MILLISECONDS_PER_MINUTE, { noUnits: index + 1 !== length })
      )
      .join(", ");
  } else {
    // else return all with units
    return link.durations.map((duration) => getDurationString(duration * MILLISECONDS_PER_MINUTE)).join(", ");
  }
};
